html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
.root {
  height: 100%;
  width: 100%;
}

/* @import '~@shell-ui/core/src/styles/fonts.css'; */

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.toolbar {
  padding-left: 0px;
}

.spinner_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 80px;
  height: 80px;
  font-family: 'Shell-Light', sans-serif;
  font-size: 14px;
  text-align: center;
  background-color: white;
}
.spinner_wrapper div {
  width: 32px;
  height: 32px;
  animation: spinner_animation 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.spinner_wrapper div:after,
.spinner_wrapper div:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
}
.spinner_wrapper div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.spinner_wrapper div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}

.spinner_wrapper.inline {
  width: 32px;
  height: 32px;
  position: absolute;
  display: inline-block;
  background-color: transparent;
  transform: translateY(-8px);
}

.spinner_wrapper.inline span {
  display: block;
}

.spinner_wrapper.custom {
  width: 80px;
  height: 80px;
  position: absolute;
  display: inline-block;
  background-color: transparent;
  transform: translateY(-8px);
}

.spinner_wrapper.custom div {
  width: 80px;
  height: 80px;
}

.spinner_wrapper.overlay {
  position: fixed;
  background-color: rgba(255, 255, 255);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  z-index: 9999;
}

.spinner_wrapper.overlay div {
  width: 192px;
  height: 192px;
}

.spinner_wrapper.overlay span {
  background-color: white;
  padding: 14px;
}

@keyframes spinner_animation {
  0% {
    transform: scale(0.95);
    opacity: 1;
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    opacity: 0.5;
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.spinner_background {
  width: 100%;
  height: 100%;
  top: 0px;
  color: '#000000cf';
  font-size: 18px;
}

.header-shell-logo {
  align-items: center;
  display: flex;
}
.header-shell-logo img {
  width: auto;
  height: 40px;
}
.header-logo-text{
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: #DD1D21;
  margin-left: 10px;
}
.header-logo-text .text1{
  font-size: 16px;
  font-weight: 600;
}
.header-logo-text .text2{
  font-size: 22px;
  font-weight: 700;
}
.header-text {
  color: #484848;
  font-size: 20px;
  margin: 0px 10px;
  font-weight: 600;
  text-align: center;
  flex: 100%;
  align-self: center;
  letter-spacing: 2px;
  font-family: 'Shell-Book', sans-serif;
  white-space: pre-wrap;
}
.custom-badge {
  color: #484848;
}

.search-result {
  display: flex;
  height: 60px;
}

.search-result-text {
  flex: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Shell-Medium',  sans-serif;
  padding: 10px 0px;
  font-size: 1rem;
  text-align: left;
}

.search-result-download {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.currency-code {
  display: inline-block;
  position: absolute;
  text-align: right;
  margin-left: 286px;
  line-height: 40px;
  cursor: default;
}

.currency-code span {
  background: #aaaaaa40;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  float: right;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
